import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import FancyImage from "../../components/template-partials/global-components/fancy-image"
import Accordion from "../../components/template-partials/global-components/accordion/accordion"
import AboutSubmenu from "../../components/template-partials/about/about-submenu"

import { UpNextCard } from "../../components/template-partials/global-components/footer-cards"

import headingBg from "../../images/1.0_backgroundheader.jpg"
import headingRound from "../../images/about-and-research-nav-image.jpg"
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import multiCenter from "../../images/1.5_Multicenterv2.png"
import singleCenter from "../../images/1.5_singlecenterv5.png"
import QuestionInfocard from "../../components/template-partials/global-components/question-infocard"
import trials from "../../images/1.5-trials.jpg"

//Cool! Export your sass Vars from a top line and use them in JS!
import * as SassVars from "../../components/variables.module.scss"
import VideoPlayer from "../../components/VideoPlayer"

const NeuroblastomaResearchPage = () => {
  return (
    <Layout>
      <SEO
        title="Neuroblastoma Research"
        description="Review results from notable neuroblastoma clinical trials and learn more about some of the leading pediatric cancer research groups for neuroblastoma."
      />

      <InteriorPageHeader
        backgroundImage={headingBg}
        roundImage={headingRound}
        alt="Neuroblastoma Research"
      >
        <h1 className="section__heading section__heading--green h1">
          Neuroblastoma Research
        </h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`About Neuroblastoma &amp; Research`}>
              <AboutSubmenu />
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons />
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <h2 className="h2 tc-barney jost-med">
                  Research groups that conduct clinical trials{" "}
                </h2>
                <p>
                  When searching for a neuroblastoma{" "}
                  <Link
                    rel="glossary"
                    to="/support-and-community/glossary/#clinical-trials"
                  >
                    clinical trial
                  </Link>{" "}
                  for your child, the amount of information available may feel
                  overwhelming. It can help to start learning about the key
                  groups driving clinical trial research in neuroblastoma.
                  Understanding the big picture will help you better navigate
                  the world of clinical trials.
                </p>
                <p>
                  Below you will find some of the key neuroblastoma research
                  groups.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row row--inner">
          <div className="columns">
            <h3 className="h2 tc-barney jost-med">
              Neuroblastoma Research Groups
            </h3>
            <p>
              Learn more about the different research groups and the goals of
              their research.
            </p>
            <VideoPlayer
              embedId="761928383"
              title="Neuroblastoma Research Groups"
            />
          </div>
        </div>
        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns">
            <Accordion className="mt-2">
              <Accordion.Item isOpen="true">
                <Accordion.Drawer>
                  <img
                    src={multiCenter}
                    width="46"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>Children’s Oncology Group (COG)</span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Is the world’s largest organization devoted exclusively
                        to childhood cancer research
                      </li>
                      <li>
                        Has more than <strong>9,000 oncology experts</strong> at{" "}
                        <strong>200+ children’s hospitals</strong>,
                        universities, and cancer centers across North America,
                        Australia, New Zealand, and Europe
                      </li>
                      <li>
                        Each year, more than 90% of children/adolescents with
                        cancer in the United States are treated at COG hospitals
                      </li>
                      <li>
                        Is supported by the National Cancer Institute (NCI)
                      </li>
                      <li>
                        Has a neuroblastoma-specific disease committee of
                        experts that collaborate to find the best treatments for
                        neuroblastoma
                      </li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>
                  <img
                    src={multiCenter}
                    width="46"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>
                    The New Approaches to Neuroblastoma Therapy (NANT)
                    Consortium
                  </span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Is the only clinical trials group in North America
                        focused solely on developing treatments for
                        relapsed/refractory{" "}
                        <Link
                          rel="glossary"
                          to="/support-and-community/glossary/#high-risk-neuroblastoma"
                        >
                          high-risk neuroblastoma
                        </Link>{" "}
                        through early phase (phase 1 or phase 2) clinical trials
                      </li>
                      <li>
                        Is made up of{" "}
                        <strong>
                          18 children’s hospitals and universities
                        </strong>{" "}
                        in the United States, Canada, Australia, France, and the
                        United Kingdom
                      </li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>
                  <img
                    src={multiCenter}
                    width="46"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>
                    The Beat Childhood Cancer Research Consortium (BeatCC)
                  </span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Offers early phase (phase 1 or phase 2) clinical trials
                        to children battling cancer
                      </li>
                      <li>
                        Is made up of{" "}
                        <strong>
                          40+ children’s hospitals and universities
                        </strong>{" "}
                        worldwide
                      </li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>
                  <img
                    src={multiCenter}
                    width="46"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>
                    International Society of Paediatric Oncology European
                    Neuroblastoma Research Network (SIOPEN)
                  </span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Aims to improve outcomes for patients with neuroblastoma
                        through clinical trials in Europe and worldwide
                      </li>
                      <li>
                        Is made up of over{" "}
                        <strong>
                          100 children’s hospitals and universities
                        </strong>{" "}
                        in Europe and Israel with 37 physician experts in
                        neuroblastoma and immunotherapy
                      </li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>
                  <img
                    src={singleCenter}
                    style={{ marginLeft: `10px`, marginRight: `12px` }}
                    width="22"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>Memorial Sloan Kettering (MSK) Cancer Center</span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Is a medical center based in New York City and the
                        surrounding areas, with both adult and pediatric cancer
                        programs
                      </li>
                      <li>Conducts and runs its own clinical trials</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
              <Accordion.Item>
                <Accordion.Drawer>
                  <img
                    src={singleCenter}
                    style={{ marginLeft: `10px`, marginRight: `12px` }}
                    width="22"
                    heigh="auto"
                    alt="multi center icon"
                    className="show-for-large"
                  />{" "}
                  <span>St. Jude Children’s Research Hospital</span>
                </Accordion.Drawer>
                <Accordion.Expanded>
                  <div className="columns">
                    <ul className="ul--dotted">
                      <li>
                        Is the only National Cancer Institute (NCI)–designated
                        comprehensive cancer center devoted solely to pediatric
                        cancer
                      </li>
                      <li>Conducts and runs its own clinical trials</li>
                      <li>Has 8 satellite clinics in the United States</li>
                    </ul>
                  </div>
                </Accordion.Expanded>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Section>

      <Section style={{ backgroundColor: SassVars.paleBlue }}>
        <div className="row row--inner align-justify align-middle">
          <div className="columns large-7">
            <h3 className="h2 tc-barney jost-med">
              Notable completed neuroblastoma clinical trials
            </h3>
            <p>
              Once{" "}
              <Link rel="glossary" to="/support-and-community/glossary/#clinical-trials">
                clinical trials
              </Link>{" "}
              are complete, the results are usually published in a medical
              journal. Medical journals are the most reliable place to find key
              information about the research study/clinical trial. The list
              below does not show every neuroblastoma clinical trial that has
              been completed, but includes a few key studies from different
              research groups.{" "}
            </p>
          </div>
          <div className="columns small-12 large-4 text-center large-text-right">
            <FancyImage
              url={trials}
              alt="Notable completed neuroblastoma clinical trials"
            />
          </div>
        </div>
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-3">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="ANBL0032"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3086629/"
                target="_blank"
                rel="noreferrer"
              >
                ANBL0032—Children’s Oncology Group (COG) Study{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Antibody added to postconsolidation associated with improved
                outcomes
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>
                  October 2001 to January 2009 at 156 COG hospitals
                </strong>
              </li>
              <li>
                <strong>1449 patients</strong> enrolled, with 226 patients
                randomized
              </li>
              <li>
                <strong>Study publication:</strong> Yu AL, Gilman AL, Ozkaynak
                MF, et al. Anti-GD2 antibody with GM-CSF, interleukin-2, and
                isotretinoin for neuroblastoma.{" "}
                <em>New England Journal of Medicine</em>.
                2010;363(14):1324-1334. doi:10.1056/NEJMoa0911123.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="ANBL0532"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6714031/"
                target="_blank"
                rel="noreferrer"
              >
                ANBL0532—Children’s Oncology Group (COG) Study{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>Tandem stem cell transplant improved survival </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>
                  November 2007 to February 2012 at 142 COG hospitals
                </strong>
              </li>
              <li>
                <strong>652 patients</strong> enrolled, with 355 patients
                randomized
              </li>
              <li>
                <strong>Study publication:</strong> Park JR, Kreissman SG,
                London WB, et al. Effect of tandem autologous stem cell
                transplant vs single transplant on event-free survival in
                patients with high-risk neuroblastoma: a randomized clinical
                trial. <em>Journal of the American Medical Association</em>.
                2019;322(8):746-755. doi:10.1001/jama.2019.11642.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="ANBL1221"
                href="https://pubmed.ncbi.nlm.nih.gov/32343642/"
                target="_blank"
                rel="noreferrer"
              >
                ANBL1221—Children’s Oncology Group (COG) Study{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Improved response rates to bone, bone marrow, and soft tissue
                disease using chemo-immunotherapy in relapsed or refractory
                disease
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>
                  February 2013 to March 2015 at 157 COG hospitals
                </strong>
              </li>
              <li>
                <strong>73 patients</strong> enrolled, with 35 patients
                randomized between the 2 arms
              </li>
              <li>
                <strong>Study publication:</strong> Mody R, Yu AL, Naranjo A, et
                al. Irinotecan, temozolomide, and dinutuximab with GM-CSF in
                children with refractory or relapsed neuroblastoma: a report
                from the Children's Oncology Group.{" "}
                <em>Journal of Clinical Oncology</em>. 2020;38(19):2160-2169.
                doi:10.1200/JCO.20.00203.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="CCG3891-R1"
                href="https://ascopubs.org/doi/pdf/10.1200/JCO.2007.13.8925"
                target="_blank"
                rel="noreferrer"
              >
                CCG-3891—Children’s Cancer Group, Randomization 1{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Myeloablative therapy and autologous stem cell transplant result
                in better 5-year EFS and OS
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>January 1991 to April 1996</strong>
              </li>
              <li>
                <strong>539 patients</strong> enrolled, with 379 patients
                randomized between 2 arms: 3 additional cycles of chemotherapy
                or consolidation with myeloablative chemotherapy, total-body
                irradiation, and autologous stem cell transplant
              </li>
              <li>
                <strong>Study publication:</strong> Matthay KK, Reynolds CP,
                Seeger RC, et al. Long-term results for children with high-risk
                neuroblastoma treated on a randomized trial of myeloablative
                therapy followed by 13-<em>cis</em>-retinoic acid: a Children’s
                Oncology Group study. <em>Journal of Clinical Oncology</em>.
                2009;27(7):1007-1013. doi:10.1200/JCO.2007.13.8925.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="CCG3891-R2"
                href="https://ascopubs.org/doi/pdf/10.1200/JCO.2007.13.8925"
                target="_blank"
                rel="noreferrer"
              >
                CCG-3891—Children’s Cancer Group, Randomization 2{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Retinoic acid after consolidation results in significantly
                improved OS
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>January 1991 to April 1996</strong>
              </li>
              <li>
                <strong>539 patients</strong> enrolled, with 258 patients
                randomized between 2 arms: patients without progression after
                consolidation randomized to receive no further therapy or
                retinoic acid for 6 months
              </li>
              <li>
                <strong>Study publication:</strong> Matthay KK, Reynolds CP,
                Seeger RC, et al. Long-term results for children with high-risk
                neuroblastoma treated on a randomized trial of myeloablative
                therapy followed by 13-<em>cis</em>-retinoic acid: a Children’s
                Oncology Group study. <em>Journal of Clinical Oncology</em>.
                2009;27(7):1007-1013. doi:10.1200/JCO.2007.13.8925.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="NB2012"
                href="https://pubmed.ncbi.nlm.nih.gov/37012551/"
                target="_blank"
                rel="noreferrer"
              >
                NANT1502—New Approaches to Neuroblastoma Therapy Consortium Study{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Lorlatinib is an ALK-targeted therapy that is safe and effective
                in relapsed and refractory patients.
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>
                  Opened September 2017 and completion is expected by 2025
                </strong>
              </li>
              <li>
                <strong>65 patients</strong> enrolled onto this dose-escalation
                study
              </li>
              <li>
                <strong>Preliminary publication:</strong> Goldsmith, K.C., Park,
                J.R., Kayser, K. et al. Lorlatinib with or without chemotherapy
                in ALK-driven refractory/relapsed neuroblastoma: phase 1 trial
                results. <em>Nat Med</em>. 2023;29(5):1092-1102. doi:10.1038/s41591-023-02297-5.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="NB2012"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6825564/"
                target="_blank"
                rel="noreferrer"
              >
                NB2022—St. Jude Children’s Research Hospital{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Antibody therapy during induction chemotherapy improved response
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>
                  May 2013 to October 2019 at one hospital, St. Jude
                </strong>
              </li>
              <li>
                <strong>153 patients</strong> enrolled, with 43 evaluable
                patients
              </li>
              <li>
                <strong>Study publication:</strong> Furman WL, Federico SM,
                McCarville MB, et al. A phase II trial of hu14.18K322A in
                combination with induction chemotherapy in children with newly
                diagnosed high-risk neuroblastoma.{" "}
                <em>Clinical Cancer Research</em>. 2022;40(21):335-344.
                doi:10.1200/JCO.21.01375.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="SIOPEN Study 2"
                href="https://pubmed.ncbi.nlm.nih.gov/30442501/"
                target="_blank"
                rel="noreferrer"
              >
                SIOPEN High-Risk Neuroblastoma Study, Randomization 2{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Cytokine interleukin-2 caused increased side effects without
                improving outcomes{" "}
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>October 2009 to August 2013 at 104 hospitals</strong>
              </li>
              <li>
                A total of <strong>422 patients</strong> enrolled, with 406
                patients randomized
              </li>
              <li>
                <strong>Study publication:</strong> Ladenstein R, Pötschger U,
                Valteau-Couanet D, et al. Interleukin 2 with anti-GD2 antibody
                ch14.18/CHO (dinutuximab beta) in patients with high-risk
                neuroblastoma (HR-NBL1/SIOPEN): a multicentre, randomised, phase
                3 trial. <em>The Lancet Oncology</em>. 2018;19(12):1617-1629.
                doi:10.1016/S1470-2045(18)30578-3.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="SIOPEN Study 1"
                href="https://pubmed.ncbi.nlm.nih.gov/28259608/"
                target="_blank"
                rel="noreferrer"
              >
                SIOPEN High-Risk Neuroblastoma Study, Randomization 1{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Consolidation treatment with busulfan/melphalan associated with
                improved outcomes{" "}
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>June 2002 to October 2010 at 128 hospitals</strong>
              </li>
              <li>
                <strong>1347 patients</strong> enrolled, with 598 randomized
              </li>
              <li>
                <strong>Study publication:</strong> Ladenstein R, Pötschger U,
                Pearson ADJ, et al. Busulfan and melphalan versus carboplatin,
                etoposide, and melphalan as high-dose chemotherapy for high-risk
                neuroblastoma (HR-NBL1/SIOPEN): an international, randomised,
                multi-arm, open-label, phase 3 trial.{" "}
                <em>The Lancet Oncology</em>. 2017;18(4):500-514.
                doi:10.1016/S1470-S2045(17)30070-0.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="NMTRC003"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6160434/"
                target="_blank"
                rel="noreferrer"
              >
                NMTRC003—Beat Childhood Cancer{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Externally controlled analyses show relapse risk reduction with
                addition of DFMO post immunotherapy{" "}
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>June 2012 to February 2016 at 22 hospitals</strong>
              </li>
              <li>
                <strong>140 patients</strong> enrolled, with 139 evaluated
              </li>
              <li>
                <strong>Study publication:</strong> Sholler GLS, Ferguson W,
                Bergendahl G, et al. Maintenance DFMO increases survival in high
                risk neuroblastoma. <em>Scientific Reports</em>.
                2018;8(1):14445. Published 2018 Sep 27.
                doi:10.1038/s41598-018-32659-w.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns large-4 small-12">
            <p>
              <a
                data-gtm-event-category="External Link"
                data-gtm-event-action="Click"
                data-gtm-event-label="MSK12230"
                href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6440722/"
                target="_blank"
                rel="noreferrer"
              >
                MSK 12-230—Memorial Sloan Kettering Cancer Center{" "}
                <small>
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </small>
              </a>
            </p>
            <p>
              <em>
                Humanized antibody phase 1 study for relapsed or refractory
                neuroblastoma{" "}
              </em>
            </p>
          </div>
          <div className="columns">
            <ul className="ul--dotted">
              <li>
                <strong>December 2012 to May 2016 at 1 hospital, MSK</strong>
              </li>
              <li>
                <strong>57 patients</strong>
              </li>
              <li>
                <strong>Study publication:</strong> Kushner BH, Cheung IY, Modak
                S, et al. Humanized 3F8 anti-GD2 monoclonal antibody dosing with
                granulocyte-macrophage colony-stimulating factor in patients
                with resistant neuroblastoma: a phase 1 clinical trial.{" "}
                <em>JAMA Oncology</em>. 2018;4(12):1729-1735. Published 2018 Sep
                20. doi:10.1001/jamaoncol.2018.4005.
              </li>
            </ul>
          </div>
        </div>
        {/*Trial*/}
        {/*Trial*/}
        <div className="row row--inner lg_mt-3 lg_mb-3 mt-1 mb-1">
          <div className="hr lg_mb-3 mb-2"></div>
          <div className="columns">
            <p>
              <Link to="https://www.cancer.gov/research/participate/clinical-trials-search">
                If you and your child’s oncologists are considering a clinical
                trial for your child, you can use the helpful clinical trial
                search tool to see if there are any in your area.
              </Link>
            </p>
          </div>
        </div>
        {/*Trial*/}
      </Section>

      <Section>
        <QuestionInfocard
          heading="Can we talk about the results of the clinical trial that my child's therapy is based on?"
          text="Find more helpful questions to ask your doctor."
          link="/diagnosis/doctor-discussion-guide/"
        />
      </Section>

      <InteriorPageFooter>
        <UpNextCard
          topic="Coping With a Neuroblastoma Diagnosis"
          title="Has your child recently been diagnosed?"
          lead="Go to the Newly Diagnosed section to start your journey as a caregiver."
          url="/diagnosis/"
        />
      </InteriorPageFooter>
    </Layout>
  )
}

export default NeuroblastomaResearchPage
